body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.card {
  border: 0 !important;
}
.time-box {
  width: 67px;
  margin: 3px auto 0 auto;
  display: block;
  padding: 0.2em 0;
  border-radius: 0.25rem;
  font-size: 12px;
  min-height: 22.78px;
}
.row-no-padding [class*='col-'] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.popover {
  max-width: 100% !important;
  border: solid 3px !important;
  border-radius: 10px !important;
  top: 0px !important;
  margin-left: 220px !important;
  left: 79px !important;
}
.other-popover {
  margin-left: -345px !important;
  padding: 10px
}

.arrow {
  display: none !important;
}
.popover.bs-popover-bottom {
  margin-top: 3.5rem !important;
}
.toss.popover {
  max-width: 100% !important;
  border: solid 3px !important;
  border-radius: 10px !important;
  top: 0px !important;
  margin-left: 220px !important;
  left: 12px !important;
}
.toss.popover.bs-popover-bottom {
  margin-top: 0.5rem !important;
}
.wicket.popover {
  margin-left: 7px !important;
  left: 73px !important;
  max-width: 392px !important;
}
.edit-players.popover {
  margin-left: -11px !important;
  left: 32px !important;
  max-width: 482px !important;
}
.add-break.popover {
  margin-left: 0px !important;
  left: 157px !important;
  max-width: 482px !important;
  margin-top: 5px !important;
}
.more-break.popover {
  margin-left: 0px !important;
  left: 126px !important;
  max-width: 482px !important;
  margin-top: 5px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.pre-add-break.popover {
  margin-left: 0px !important;
  left: 157px !important;
  max-width: 482px !important;
  margin-top: 5px !important;
}
.retired-batsman.popover {
  margin-left: 0px !important;
  left: 86px !important;
  width: 340px !important;
  margin-top: 15px !important;
  height: 175px !important;
}
.penalty-runs.popover {
  margin-left: 0px !important;
  left: 86px !important;
  width: 290px !important;
  margin-top: 75px !important;
  height: 125px !important;
}
.text-center {
  text-align: center;
}

.float-left {
  float: left;
}
.w-100 {
  width: 100%;
}
.mt-3 {
  margin-top: 3rem;
}
.ml-3 {
  margin-left: 3rem;
}
.followon-wrapper {
  float: right;
}
.followon-label {
  padding-right: 5px;
}
